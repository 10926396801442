export const SET_IS_AUTH = "SET_IS_AUTH"
export const SET_TOKEN = "SET_TOKEN"
export const LOGOUT = "LOGOUT"
export const FETCH_USER_LOADING = "FETCH_USER_LOADING"
export const FETCH_USER = "FETCH_USER"

export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const SET_ERROR = "SET_ERROR"
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS"
export const SET_AUTH_DATA = "SET_AUTH_DATA"
export const SET_LOADING = "SET_LOADING"

export const SET_TOKEN_LOADING = "SET_TOKEN_LOADING"
export const SET_TOKEN_ERROR = "SET_TOKEN_ERROR"
export const SET_PROFILE = "SET_PROFILE"

export const SET_SITES = "SET_SITES"
export const GET_ALL_APP_MENU = "GET_ALL_APP_MENU"

export const SET_ACCESS_HUB = "SET_ACCESS_HUB"
export const FETCH_ALL_HUB_LIST_SUCCESS = "FETCH_ALL_HUB_LIST_SUCCESS"
export const SET_EDIT_CHANGE_HUB = "SET_EDIT_CHANGE_HUB"

export const SET_USER_DEVICE_TOKEN = "SET_USER_DEVICE_TOKEN"
export const SET_CHATBOT_VISIBILITY = "SET_CHATBOT_VISIBILITY"
