import { combineReducers } from "redux"
import Auth from "./Auth/reducer"
import Layout from "./Layout/reducer"
import Home from "./Home/reducer"
import Messages from "./Message/reducer"
import Report from "./Report/reducer"
import Announcements from "../modules/home/components/Announcement/store/reducer"
import Calendar from "../modules/home/components/Calendar/store/reducer"
import Invitation from "./InviteUser/reducer"
import ManageAdmin from "../modules/manageAdmin/store/reducer"
import Notification from "../modules/notification/store/reducer"
import Subscription from "./Subscription/reducer"
import PhotoLibrary from "../modules/PhotoLibrary/store/reducer"

const rootReducer = combineReducers({
  Auth,
  Layout,
  Home,
  Messages,
  Report,
  Announcements,
  Calendar,
  Invitation,
  ManageAdmin,
  Notification,
  Subscription,
  PhotoLibrary,
})

export default rootReducer
