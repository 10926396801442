export const SET_LOADING = "SET_LOADING"
export const SET_ACCESS_INVITE = "SET_ACCESS_INVITE"
export const SET_MEMBER_INVITATION_LOADING = "SET_MEMBER_INVITATION_LOADING"
export const SET_NOT_ACCEPT_INVITATION_LOADING =
  "SET_NOT_ACCEPT_INVITATION_LOADING"

// Not accept invitation
export const GET_INVITE = "GET_INVITE"
export const SET_INVITATION_PAGE = "SET_INVITATION_PAGE"
export const SET_TOTAL_RECORDS = "SET_TOTAL_RECORDS"

//left member
export const SET_TOTAL_LEFT_MEMBER = "SET_TOTAL_LEFT_MEMBER"
export const SET_TOTAL_LEFT_MEMBER_PAGE = "SET_TOTAL_LEFT_MEMBER_PAGE"
export const SET_TOTAL_LEFT_MEMBER_RECORDS = "SET_TOTAL_LEFT_MEMBER_RECORDS"

// Accept invitation
export const GET_ACCEPT_INVITE = "GET_ACCEPT_INVITE"
export const SET_ACCEPT_PAGE = "SET_ACCEPT_PAGE"
export const SET_ACCEPT_TOTAL_RECORDS = "SET_ACCEPT_TOTAL_RECORDS"
export const SET_REMOVE_USER_ITSELF_HUB = "SET_REMOVE_USER_ITSELF_HUB"
export const GET_INVITE_LEFT_MEMBER = "GET_INVITE_LEFT_MEMBER"

// Export Invitation
export const SET_EXPORT_INVITE = "SET_EXPORT_INVITE"
export const SET_IMPORT_MEMBER = "SET_IMPORT_MEMBER"
