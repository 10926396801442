export const SET_LOADING = "SET_LOADING"

export const FETCH_CHAT_CONTACT = "FETCH_CHAT_CONTACT"

export const SET_LOADING_MESSAGE = "SET_LOADING_MESSAGE"

export const FETCH_HUBS_USERS_FOR_MESSAGES = "FETCH_HUBS_USERS_FOR_MESSAGES"

export const FETCH_ALL_CHAT_MESSAGES = "FETCH_ALL_CHAT_MESSAGES"

export const SET_CHAT_MSG_LOADING = "SET_CHAT_MSG_LOADING"

export const SAVE_MESSAGES = "SAVE_MESSAGES"

export const FETCH_GROUP_CHAT_MEMBERS = "FETCH_GROUP_CHAT_MEMBERS"

export const SAVE_MESSAGE_REPLAY = "SAVE_MESSAGE_REPLAY"

export const GET_MESSAGE_REPLIES_DATA = "GET_MESSAGE_REPLIES_DATA"

export const SET_TOTAL_CHAT_RECORDS = "SET_TOTAL_CHAT_RECORDS"

export const SET_TOTAL_CHAT_MESSAGE = "SET_TOTAL_CHAT_MESSAGE"

export const SET_TOTAL_USER = "SET_TOTAL_USER"

export const SET_TOTAL_REPLY = "SET_TOTAL_REPLY"

export const SET_MESSAGE_PAGE = "SET_MESSAGE_PAGE"

export const SET_USER_LIST_PAGE = "SET_USER_LIST_PAGE"

export const RESET_MESSAGE_COUNT = "RESET_MESSAGE_COUNT"

export const SET_MESSAGES_FROM_NOTIFICATION = "SET_MESSAGES_FROM_NOTIFICATION"

export const SET_REPLY_FROM_NOTIFICATION = "SET_REPLY_FROM_NOTIFICATION"

export const DELETE_CONVERSATION = "DELETE_CONVERSATION"

export const LEAVE_CHAT = "LEAVE_CHAT"

export const SET_HAS_MORE = "SET_HAS_MORE"

export const SET_SEARCH = "SET_SEARCH"

export const SET_CHAT_MESSAGE_PAGE = "SET_CHAT_MESSAGE_PAGE"

export const SET_CHAT_MESSAGE_HAS_MORE = "SET_CHAT_MESSAGE_HAS_MORE"

export const SET_REPLY_MESSAGE_PAGE = "SET_REPLY_MESSAGE_PAGE"

export const SET_REPLY_MESSAGE_HAS_MORE = "SET_REPLY_MESSAGE_HAS_MORE"

export const SET_CHAT_HEADER_LOADING = "SET_CHAT_HEADER_LOADING"

export const MANAGE_REPLY_SECTION = "MANAGE_REPLY_SECTION"

export const MANAGE_SECTIONS_IN_SMALL_SCREEN = "MANAGE_SECTIONS_IN_SMALL_SCREEN"

export const SET_REPLY_SECTION_DATA = "SET_REPLY_SECTION_DATA"

export const SET_SELECTED_CHATS = "SET_SELECTED_CHATS"

export const SET_MUTE_CHAT_DATA = "SET_MUTE_CHAT_DATA"

export const SET_PIN_CHAT_DATA = "SET_PIN_CHAT_DATA"

export const SET_CHAT_HEADER_DATA = "SET_CHAT_HEADER_DATA"

export const SET_NEW_CHAT_USER_ID = "SET_NEW_CHAT_USER_ID"

export const SET_USER_LISTING_RESULT = "SET_USER_LISTING_RESULT"

export const SELECTED_USER_DETAIL = "SELECTED_USER_DETAIL"

export const CHAT_BOTTOM = "CHAT_BOTTOM"

export const REPLY_BOTTOM = "REPLY_BOTTOM"

export const IS_FIRST_SELECT = "IS_FIRST_SELECT"

export const GET_ALL_CHAT_CONTACT_LIST = "GET_ALL_CHAT_CONTACT_LIST"

export const UNIQUE_USER_LIST = "UNIQUE_USER_LIST"

export const SET_ALL_USER_LISTING_RESULT = "SET_ALL_USER_LISTING_RESULT"
