import {
  SET_LOADING,
  SET_SWITCH_ID,
  SET_HUB_FILTER,
  FETCH_HUB_LIST_SUCCESS,
  SET_LOADING_HUB_LIST,
  FETCH_ACCESS_HUB_COUNT,
  GET_HUB_DETAILS_SUCCESS,
  SET_LOADING_HUB_DETAIL,
  GET_BANNER,
  GET_SWITCH_DATA,
  FETCH_HUB_COUNT,
  SET_SAVE_HUB,
  SET_DELETE_HUB,
  SET_UPDATE_BANNER,
  SET_REMOVE_INVITATION_HUB,
  SET_TOP_MOST_MODAL,
  SET_PERMISSIONS,
  FETCH_SHARED_DASHBOARD_ACCESS,
  SET_USER_IMAGE_BANNER,
  SET_REMOVING_USER_BANNER,
} from "./actionTypes"
import produce from "immer"

const initialState = {
  loading: false,
  loadingHubList: true,
  removingUserBanner: false,
  loadingHubDetail: true,
  switchId: 0,
  hubFilter: 1,
  hubList: [],
  accessHubCount: 0,
  sharedDashboardAccess: false,
  hubDetails: [],
  bannerList: [],
  userBannerList: [],
  count: 0,
  switchDataList: "",
  topMostModal: {},
  permissions: null,
}

const reducer = produce((state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      }
    case SET_SWITCH_ID:
      return {
        ...state,
        switchId: payload,
      }
    case SET_HUB_FILTER:
      return {
        ...state,
        hubFilter: payload,
      }
    case FETCH_HUB_LIST_SUCCESS:
      return {
        ...state,
        hubList: payload.is_refresh
          ? payload.rows
          : [...state.hubList, ...payload.rows],
      }
    case SET_LOADING_HUB_LIST:
      return {
        ...state,
        loadingHubList: payload,
      }
    case SET_REMOVING_USER_BANNER:
      return {
        ...state,
        removingUserBanner: payload,
      }
    case FETCH_ACCESS_HUB_COUNT:
      return {
        ...state,
        accessHubCount: payload,
      }
    case FETCH_SHARED_DASHBOARD_ACCESS:
      return {
        ...state,
        sharedDashboardAccess: payload,
      }
    case FETCH_HUB_COUNT:
      return {
        ...state,
        count: payload,
      }
    case GET_HUB_DETAILS_SUCCESS:
      return {
        ...state,
        hubDetails: payload,
      }
    case SET_LOADING_HUB_DETAIL:
      return {
        ...state,
        loadingHubDetail: payload,
      }
    case GET_BANNER:
      return {
        ...state,
        bannerList: payload,
      }
    case SET_USER_IMAGE_BANNER:
      return {
        ...state,
        userBannerList: payload,
      }
    case GET_SWITCH_DATA:
      return {
        ...state,
        switchDataList: payload,
      }
    case SET_SAVE_HUB:
      return {
        ...state,
        hubList: state.hubList.map(item => {
          if (item.hub_id === payload.values.hub_id) {
            return payload.rows
          }
          return item
        }),
      }
    case SET_DELETE_HUB:
      return {
        ...state,
        hubList: state.hubList.filter(i => i.hub_id !== payload.hub_id),
      }
    case SET_REMOVE_INVITATION_HUB:
      return {
        ...state,
        hubList: state.hubList.filter(i => i.hub_id !== payload),
      }
    case SET_UPDATE_BANNER:
      return {
        ...state,
        hubList: state.hubList.map(item => {
          if (item.hub_id === payload.hub_id) {
            const updatedObj = {
              ...item,
              banner_image: payload.image,
              banner_thumbnail_image: payload.image,
            }
            return updatedObj
          }
          return item
        }),
      }
    case SET_TOP_MOST_MODAL:
      return {
        ...state,
        topMostModal: payload,
      }
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: payload,
      }
    default:
      return state
  }
})

export default reducer
