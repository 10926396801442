import {
  SET_LOADING,
  GET_ADMIN_LIST,
  CREATE_ADMIN,
  DELETE_ADMIN,
  SET_ADMIN_PAGE,
  SET_TOTAL_RECORDS,
} from "./actionTypes"

import produce from "immer"

const initialState = {
  loading: false,
  adminList: [],
  adminPage: 1,
  total_records: 0,
}

const manageAdminReducer = produce((state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_LOADING:
      state.loading = payload
      break
    case GET_ADMIN_LIST:
      return {
        ...state,
        adminList: payload.is_refresh
          ? payload.rows
          : [...state.adminList, ...payload.rows],
      }
    case CREATE_ADMIN:
      return {
        ...state,
        adminList: state.adminList.map(item => {
          if (item.admin_id === payload.admin_id) {
            const updated = {
              ...item,
              first_name: payload.first_name,
              last_name: payload.last_name,
              receiver_email: payload.receiver_email,
            }
            return updated
          }
          return item
        }),
      }

    case DELETE_ADMIN:
      return {
        ...state,
        adminList: state.adminList.filter(i => i.admin_id !== payload),
      }
    case SET_ADMIN_PAGE:
      state.adminPage = payload
      break
    case SET_TOTAL_RECORDS:
      state.total_records = payload
      break

    default:
      return state
  }
})

export default manageAdminReducer
