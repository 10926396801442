import {
  SET_LOADING,
  LIST_LOG_CALENDAR,
  LIST_PUBLIC_LOG_CALENDAR,
  GET_LIST_CALENDAR_ITEMS,
  GET_LIST_ANNOUNCEMENTS_ITEMS,
  DELETE_CALENDAR_EVENT,
  SET_CALENDAR_COUNT,
  SET_CALENDAR_LOADING,
  RSVP_LIST,
  RSVP_RESPONSE_LIST,
  SET_RSVP_DATA,
  GET_PUBLIC_LIST_CALENDAR_ITEMS,
} from "./actionTypes"

import produce from "immer"

const initialState = {
  loading: false,
  logCalendarList: [],
  listCalendarItem: [],
  listAnnouncementItem: [],
  calenderCount: 0,
  calendarLoading: false,
  rsvpResponseList: [],
  rsvpData: {},
}

const calendarReducer = produce((state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_LOADING:
      state.loading = payload
      break
    case SET_CALENDAR_LOADING:
      state.calendarLoading = payload
      break
    case LIST_LOG_CALENDAR:
      state.logCalendarList = payload
      break
    case LIST_PUBLIC_LOG_CALENDAR:
      state.logCalendarList = payload
      break
    case GET_LIST_CALENDAR_ITEMS:
      return {
        ...state,
        listCalendarItem: payload.is_refresh
          ? payload.rows
          : [...state.listCalendarItem, ...payload.rows],
      }
    case GET_PUBLIC_LIST_CALENDAR_ITEMS:
      return {
        ...state,
        listCalendarItem: payload.is_refresh
          ? payload.rows
          : [...state.listCalendarItem, ...payload.rows],
      }
    case GET_LIST_ANNOUNCEMENTS_ITEMS:
      return {
        ...state,
        listAnnouncementItem: payload.is_refresh
          ? payload.announcement_rows
          : [...state.listAnnouncementItem, ...payload.announcement_rows],
      }
    case DELETE_CALENDAR_EVENT:
      state.loading = payload
      break
    case SET_CALENDAR_COUNT:
      state.calenderCount = payload
      break
    case RSVP_LIST:
      state.rsvpList = payload
      break
    case RSVP_RESPONSE_LIST:
      state.rsvpResponseList = payload
      break
    case SET_RSVP_DATA:
      state.rsvpData = payload
      break
    default:
      return state
  }
})

export default calendarReducer
