import {
  SET_LOADING,
  SET_HAS_MORE,
  SET_ANNOUNCEMENT_LOADING,
  GET_NOTIFICATION_ANNOUNCEMENT,
  GET_ANNOUNCEMENT,
  SET_ANNOUNCEMENT_PAGE,
  SET_ANNOUNCEMENT_TOTAL_RECORDS,
  SET_DELETE_ANNOUNCEMENT,
} from "./actionTypes"

import produce from "immer"

const initialState = {
  loading: false,
  hasMore: true,
  announcementLoading: false,
  notificationAnnouncementList: "",
  announcementList: [],
  page: 1,
  total_records: 0,
}

const announcementReducer = produce((state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_LOADING:
      state.loading = payload
      break
    case SET_HAS_MORE:
      state.hasMore = payload
      break
    case SET_ANNOUNCEMENT_LOADING:
      state.announcementLoading = payload
      break
    case GET_NOTIFICATION_ANNOUNCEMENT:
      state.notificationAnnouncementList = payload
      break
    case GET_ANNOUNCEMENT:
      return {
        ...state,
        announcementList: payload.is_refresh
          ? payload.rows
          : [...state.announcementList, ...payload.rows],
      }
    case SET_ANNOUNCEMENT_PAGE:
      state.page = payload
      break
    case SET_ANNOUNCEMENT_TOTAL_RECORDS:
      state.total_records = payload
      break
    case SET_DELETE_ANNOUNCEMENT:
      return {
        ...state,
        announcementList: state.announcementList.filter(
          i => i.announcement_id !== payload.announcement_id
        ),
      }
    default:
      return state
  }
})

export default announcementReducer
