export const SET_LOADING = "SET_LOADING"

export const SET_SAVE_ALBUM = "SET_SAVE_ALBUM"
export const FETCH_ALBUM_COUNT = "FETCH_ALBUM_COUNT"
export const FETCH_ALBUM_LIST_SUCCESS = "FETCH_ALBUM_LIST_SUCCESS"
export const SET_DELETE_ALBUM = "SET_DELETE_ALBUM"

export const SET_SAVE_MEDIA = "SET_SAVE_MEDIA"
export const FETCH_MEDIA_COUNT = "FETCH_MEDIA_COUNT"
export const FETCH_MEDIA_LIST_SUCCESS = "FETCH_MEDIA_LIST_SUCCESS"
export const SET_DELETE_MEDIA = "SET_DELETE_MEDIA"
export const SET_PHOTO_LIBRARY_FILTER = "SET_PHOTO_LIBRARY_FILTER"
