import {
  SET_IS_AUTH,
  SET_TOKEN,
  LOGOUT,
  FETCH_USER_LOADING,
  FETCH_USER,
  SET_ERROR,
  SET_PROFILE,
  SET_LOADING,
  SET_SITES,
  GET_ALL_APP_MENU,
  SET_ACCESS_HUB,
  FETCH_ALL_HUB_LIST_SUCCESS,
  SET_EDIT_CHANGE_HUB,
  SET_CHATBOT_VISIBILITY,
} from "./actionTypes"

import produce from "immer"

const initialState = {
  isAuth: false,
  token: null,
  fetchUserLoading: false,
  user: null,
  ///user: null,
  chatBoxVisibility: "minimized",
  error: "",
  loading: false,
  sites: [],
  appMenuList: [],
  subscriptionHub: "",
  allHubList: [],
}

const authReducer = produce((state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_IS_AUTH:
      return {
        ...state,
        isAuth: payload,
      }
    case SET_TOKEN:
      return {
        ...state,
        token: payload,
      }
    case LOGOUT:
      return {
        ...state,
        isAuth: false,
        token: null,
        user: null,
      }
    case FETCH_USER_LOADING:
      return {
        ...state,
        fetchUserLoading: payload,
      }
    case FETCH_USER:
      return {
        ...state,
        user: payload,
      }
    case SET_ERROR:
      state.error = payload
      break
    case SET_PROFILE:
      state.user = payload
      state.isAuth = true
      break
    case SET_LOADING:
      state.loading = payload
      break
    case SET_SITES:
      state.sites = payload
      break
    case GET_ALL_APP_MENU:
      state.appMenuList = payload
      break
    case SET_ACCESS_HUB:
      state.subscriptionHub = payload
      break
    case FETCH_ALL_HUB_LIST_SUCCESS:
      return {
        ...state,
        allHubList: payload,
      }
    case SET_EDIT_CHANGE_HUB:
      return {
        ...state,
        allHubList: state.allHubList.map(item => {
          if (item.hub_id === payload.hub_id) {
            const updated = {
              hub_id: payload.hub_id,
              hub_title: payload.title,
            }
            return updated
          }
          return item
        }),
      }
    case SET_CHATBOT_VISIBILITY:
      return { ...state, chatBoxVisibility: payload }
    default:
      return state
  }
})

export default authReducer
