import {
  SET_LOADING,
  SET_ACCESS_INVITE,
  SET_MEMBER_INVITATION_LOADING,
  SET_NOT_ACCEPT_INVITATION_LOADING,
  GET_INVITE,
  SET_INVITATION_PAGE,
  SET_TOTAL_RECORDS,
  GET_ACCEPT_INVITE,
  SET_ACCEPT_PAGE,
  SET_ACCEPT_TOTAL_RECORDS,
  SET_EXPORT_INVITE,
  SET_IMPORT_MEMBER,
  SET_REMOVE_USER_ITSELF_HUB,
  GET_INVITE_LEFT_MEMBER,
  SET_TOTAL_LEFT_MEMBER_RECORDS,
  SET_TOTAL_LEFT_MEMBER_PAGE,
} from "./actionTypes"

import produce from "immer"

const initialState = {
  loading: false,
  loadingMemberInvitation: true,
  loadingNoAcceptInvitation: true,
  accessInvite: 0,
  notAcceptedInvites: [],
  leftMemberList: [],
  leftMemberPage: 1,
  totalLeftMemberRecords: 0,
  invitationPage: 1,
  total_records: 0,
  acceptedInvites: [],
  page_accept: 1,
  total_records_accept: 0,
  exportInvites: [],
  inviteHubData: [],
  importMember: [],
}

const invitationReducer = produce((state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_LOADING:
      state.loading = payload
      break
    case SET_ACCESS_INVITE:
      state.accessInvite = payload
      break
    case SET_MEMBER_INVITATION_LOADING:
      state.loadingMemberInvitation = payload
      break
    case GET_INVITE:
      state.notAcceptedInvites = payload
      break
    case GET_INVITE_LEFT_MEMBER:
      state.leftMemberList = payload
      break
    case SET_TOTAL_LEFT_MEMBER_PAGE:
      state.leftMemberPage = payload
      break
    case SET_TOTAL_LEFT_MEMBER_RECORDS:
      state.totalLeftMemberRecords = payload
      break
    case SET_NOT_ACCEPT_INVITATION_LOADING:
      state.loadingNoAcceptInvitation = payload
      break
    case SET_INVITATION_PAGE:
      state.invitationPage = payload
      break
    case SET_TOTAL_RECORDS:
      state.total_records = payload
      break
    case GET_ACCEPT_INVITE:
      state.acceptedInvites = payload
      break
    case SET_ACCEPT_PAGE:
      state.page_accept = payload
      break
    case SET_ACCEPT_TOTAL_RECORDS:
      state.total_records_accept = payload
      break
    case SET_EXPORT_INVITE:
      state.exportInvites = payload
      break
    case SET_REMOVE_USER_ITSELF_HUB:
      state.inviteHubData = payload
      break
    case SET_IMPORT_MEMBER:
      state.importMember = payload
      break

    default:
      return state
  }
})

export default invitationReducer
