import {
  SET_LOADING,
  GET_VISITOR_REPORT,
  GET_VISITOR_REPORT_DETAILS,
  SET_TOTAL_RECORDS,
} from "./actionTypes"

import produce from "immer"

const initialState = {
  loading: false,
  visitorReportList: [],
  visitorReportDetailsList: [],
  total_records: 0,
}

const reportReducer = produce((state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_LOADING:
      state.loading = payload
      break
    case GET_VISITOR_REPORT:
      state.visitorReportList = payload
      break
    case GET_VISITOR_REPORT_DETAILS:
      return {
        ...state,
        visitorReportDetailsList: payload.is_refresh
          ? payload.rows
          : [...state.visitorReportDetailsList, ...payload.rows],
      }
    case SET_TOTAL_RECORDS:
      state.total_records = payload
      break
    default:
      return state
  }
})

export default reportReducer
