import {
  SET_LOADING,
  SET_SAVE_ALBUM,
  FETCH_ALBUM_COUNT,
  FETCH_ALBUM_LIST_SUCCESS,
  SET_DELETE_ALBUM,
  SET_SAVE_MEDIA,
  FETCH_MEDIA_COUNT,
  FETCH_MEDIA_LIST_SUCCESS,
  SET_DELETE_MEDIA,
  SET_PHOTO_LIBRARY_FILTER,
} from "./actionTypes"
import produce from "immer"
const initialState = {
  loading: false,
  albumList: [],
  mediaList: [],
  mediaCount: 0,
  count: 0,
  photoLibraryFilter: 1,
}

const photoLibraryReducer = produce((state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_LOADING:
      state.loading = payload
      break
    case SET_SAVE_ALBUM:
      return {
        ...state,
        albumList: state.albumList,
      }
    case FETCH_ALBUM_COUNT:
      return {
        ...state,
        count: payload,
      }
    case FETCH_ALBUM_LIST_SUCCESS:
      return {
        ...state,
        albumList: payload.is_refresh
          ? payload.rows
          : [...state.albumList, ...payload.rows],
      }
    case SET_DELETE_ALBUM:
      return {
        ...state,
        albumList: state.albumList.filter(i => i.album_id !== payload.album_id),
      }
    case SET_SAVE_MEDIA:
      return {
        ...state,
        mediaList: state.mediaList.map(item => {
          if (item.media_id === payload.values.media[0].media_id) {
            return payload.rows
          }
          return item
        }),
      }
    case FETCH_MEDIA_COUNT:
      return {
        ...state,
        mediaCount: payload,
      }
    case FETCH_MEDIA_LIST_SUCCESS:
      return {
        ...state,
        mediaList: payload.is_refresh
          ? payload.rows
          : [...state.mediaList, ...payload.rows],
      }
    case SET_DELETE_MEDIA:
      return {
        ...state,
        mediaList: state.mediaList.filter(i => i.media_id !== payload.media_id),
      }
    case SET_PHOTO_LIBRARY_FILTER:
      return {
        ...state,
        photoLibraryFilter: payload,
      }
    default:
      return state
  }
})

export default photoLibraryReducer
