import {
  SET_LOADING,
  GET_NOTIFICATION_LIST,
  GET_NOTIFICATION_SETTINGS,
  SAVE_NOTIFICATION_SETTINGS,
  READ_NOTIFICATION,
  SET_NOTIFICATION_PAGE,
  MODIFY_NOTIFICATION_LIST,
} from "./actionTypes"

import produce from "immer"

const initialState = {
  loading: false,
  notificationList: {},
  notificationSettings: [],
  notificationPage: 1,
  allNotification: [],
}

const notificationReducer = produce((state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_LOADING:
      state.loading = payload
      break
    case GET_NOTIFICATION_LIST:
      state.notificationList = payload
      break
    case GET_NOTIFICATION_SETTINGS:
      state.notificationSettings = payload
      break
    case SAVE_NOTIFICATION_SETTINGS:
      state.loading = payload
      break
    case READ_NOTIFICATION:
      state.loading = payload
      break
    case SET_NOTIFICATION_PAGE:
      state.notificationPage = payload
      break
    case MODIFY_NOTIFICATION_LIST:
      state.allNotification = payload
      break
    default:
      return state
  }
})

export default notificationReducer
