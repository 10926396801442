import {
  FETCH_CHAT_CONTACT,
  SET_LOADING_MESSAGE,
  SET_LOADING,
  FETCH_HUBS_USERS_FOR_MESSAGES,
  FETCH_ALL_CHAT_MESSAGES,
  SET_CHAT_MSG_LOADING,
  SAVE_MESSAGES,
  FETCH_GROUP_CHAT_MEMBERS,
  SAVE_MESSAGE_REPLAY,
  GET_MESSAGE_REPLIES_DATA,
  SET_MESSAGE_PAGE,
  SET_TOTAL_CHAT_RECORDS,
  RESET_MESSAGE_COUNT,
  SET_MESSAGES_FROM_NOTIFICATION,
  SET_REPLY_FROM_NOTIFICATION,
  DELETE_CONVERSATION,
  LEAVE_CHAT,
  SET_TOTAL_CHAT_MESSAGE,
  SET_USER_LIST_PAGE,
  SET_TOTAL_USER,
  SET_HAS_MORE,
  SET_SEARCH,
  SET_TOTAL_REPLY,
  SET_CHAT_MESSAGE_HAS_MORE,
  SET_CHAT_MESSAGE_PAGE,
  SET_CHAT_HEADER_LOADING,
  MANAGE_REPLY_SECTION,
  MANAGE_SECTIONS_IN_SMALL_SCREEN,
  SET_REPLY_SECTION_DATA,
  SET_SELECTED_CHATS,
  SET_MUTE_CHAT_DATA,
  SET_PIN_CHAT_DATA,
  SET_REPLY_MESSAGE_PAGE,
  SET_REPLY_MESSAGE_HAS_MORE,
  SET_CHAT_HEADER_DATA,
  SET_NEW_CHAT_USER_ID,
  SET_USER_LISTING_RESULT,
  SELECTED_USER_DETAIL,
  CHAT_BOTTOM,
  REPLY_BOTTOM,
  IS_FIRST_SELECT,
  GET_ALL_CHAT_CONTACT_LIST,
  UNIQUE_USER_LIST,
  SET_ALL_USER_LISTING_RESULT,
} from "./actionTypes"
import produce from "immer"

const initialState = {
  loading: false,
  loadingMessage: false,
  loadingChatMessage: false,
  chatContactList: [],
  usersList: [],
  chatMessages: [],
  singleMessage: "",
  chatGroupMessage: [],
  chatGroupMembers: [],
  getSaveMessageReplay: [],
  messageReplies: [],
  messagePage: 1,
  total_chat_records: 0,
  chatMessageFromNotification: {},
  ReplyFromNotification: {},
  total_chat_message: 0,
  userListPage: 1,
  total_user: 0,
  hasMore: true,
  mySearch: "",
  totalReply: 0,
  chatMessageHasMore: true,
  chatMessagePage: { page: 1, load: true },
  replyMessageHasMore: true,
  replyMessagePage: { page: 1, load: true },
  chatHeaderLoading: false,
  replySectionManage: false,
  sectionsInSmallScreenManage: "contact",
  replySectionData: {},
  selectedOrActiveChats: {},
  chatHeaderData: {},
  NewChatUserId: -1,
  userListingResult: [],
  selectedUserDetail: {},
  goToChatBottom: {},
  gotoReplyBottom: {},
  isFirstSelect: true,
  allChatContactList: [],
  userUniqueList: [],
  allUserListingResult: [],
}

const messagesReducer = produce((state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case RESET_MESSAGE_COUNT:
      let temp = state.chatContactList.map((v, i) => {
        return v.chat_room_id === payload
          ? { ...v, total_unread_messages: 0 }
          : v
      })
      state.chatContactList = temp
      break
    case SET_CHAT_HEADER_LOADING:
      state.chatHeaderLoading = payload
      break
    case SET_LOADING:
      state.loading = payload
      break
    case FETCH_CHAT_CONTACT:
      return {
        ...state,
        chatContactList: payload.is_refresh
          ? payload.rows
          : [...state.chatContactList, ...payload.rows],
      }
    case SET_LOADING_MESSAGE:
      state.loadingMessage = payload
      break
    case FETCH_HUBS_USERS_FOR_MESSAGES:
      return {
        ...state,
        usersList: payload.is_refresh
          ? payload.rows
          : [...state.usersList, ...payload.rows],
      }
    case FETCH_ALL_CHAT_MESSAGES:
      return {
        ...state,
        chatMessages: payload.is_refresh
          ? payload.rows
          : [...state.chatMessages, ...payload.rows],
      }
    case SET_CHAT_MSG_LOADING:
      state.loadingChatMessage = payload
      break
    case SAVE_MESSAGES:
      state.singleMessage = payload
      break
    case FETCH_GROUP_CHAT_MEMBERS:
      state.chatGroupMembers = payload
      break
    case SAVE_MESSAGE_REPLAY:
      state.getSaveMessageReplay = payload
      break
    case GET_MESSAGE_REPLIES_DATA:
      return {
        ...state,
        messageReplies: payload.is_refresh
          ? payload.rows
          : [...state.messageReplies, ...payload.rows],
      }
    case SET_MESSAGE_PAGE:
      state.messagePage = payload
      break
    case SET_USER_LIST_PAGE:
      state.userListPage = payload
      break
    case SET_TOTAL_CHAT_RECORDS:
      state.total_chat_records = payload
      break
    case SET_TOTAL_CHAT_MESSAGE:
      state.total_chat_message = payload
      break
    case SET_TOTAL_REPLY:
      state.totalReply = payload
      break
    case SET_TOTAL_USER:
      state.total_user = payload
      break
    case SET_MESSAGES_FROM_NOTIFICATION:
      state.chatMessageFromNotification = payload
      break
    case SET_REPLY_FROM_NOTIFICATION:
      state.ReplyFromNotification = payload
      break
    case DELETE_CONVERSATION:
      return {
        ...state,
        chatContactList: state.chatContactList.filter(
          item => item.chat_room_id !== payload.chat_room_id
        ),
      }
    case LEAVE_CHAT:
      return {
        ...state,
        chatContactList: state.chatContactList.filter(
          item => item.chat_room_id !== payload.chat_room_id
        ),
      }
    case SET_HAS_MORE:
      state.hasMore = payload
      break
    case SET_SEARCH:
      state.mySearch = payload
      break
    case SET_CHAT_MESSAGE_PAGE:
      state.chatMessagePage = payload
      break
    case SET_CHAT_MESSAGE_HAS_MORE:
      state.chatMessageHasMore = payload
      break
    case SET_REPLY_MESSAGE_PAGE:
      state.replyMessagePage = payload
      break
    case SET_REPLY_MESSAGE_HAS_MORE:
      state.replyMessageHasMore = payload
      break
    case MANAGE_REPLY_SECTION:
      state.replySectionManage = payload
      break
    case MANAGE_SECTIONS_IN_SMALL_SCREEN:
      state.sectionsInSmallScreenManage = payload
      break
    case SET_REPLY_SECTION_DATA:
      state.replySectionData = payload
      break
    case SET_SELECTED_CHATS:
      state.selectedOrActiveChats = payload
      break
    case SET_MUTE_CHAT_DATA:
      state.userListingResult.forEach(x => {
        if (x.chat_room_id === payload.chat_room_id) {
          x.is_mute = payload.is_mute
        }
      })
      if (state.selectedOrActiveChats.chat_room_id === payload.chat_room_id) {
        state.selectedOrActiveChats.is_mute = payload.is_mute
      }
      break
    case SET_PIN_CHAT_DATA:
      state.userListingResult.forEach(x => {
        if (x.chat_room_id === payload.chat_room_id) {
          x.is_pin = payload.is_pin
        }
      })
      if (state.selectedOrActiveChats.chat_room_id === payload.chat_room_id) {
        state.selectedOrActiveChats.is_pin = payload.is_pin
      }
      break
    case SET_CHAT_HEADER_DATA:
      state.chatHeaderData = payload
      break
    case SET_NEW_CHAT_USER_ID:
      state.NewChatUserId = payload
      break
    case SET_USER_LISTING_RESULT:
      state.userListingResult = payload
      break
    case SELECTED_USER_DETAIL:
      state.selectedUserDetail = payload
      break
    case CHAT_BOTTOM:
      state.goToChatBottom = payload
      break
    case REPLY_BOTTOM:
      state.gotoReplyBottom = payload
      break
    case IS_FIRST_SELECT:
      state.isFirstSelect = payload
      break
    case GET_ALL_CHAT_CONTACT_LIST:
      return {
        ...state,
        allChatContactList: payload.is_refresh
          ? payload.rows
          : [...state.allChatContactList, ...payload.rows],
      }
    case UNIQUE_USER_LIST:
      state.userUniqueList = payload
      break
    case SET_ALL_USER_LISTING_RESULT:
      state.allUserListingResult = payload
      break
    default:
      return state
  }
})

export default messagesReducer
