export const SET_LOADING = "SET_LOADING"
export const SET_SWITCH_ID = "SET_SWITCH_ID"
export const SET_HUB_FILTER = "SET_HUB_FILTER"
export const CREATE_VISITOR_REPORT = "CREATE_VISITOR_REPORT"

export const FETCH_HUB_LIST_SUCCESS = "FETCH_HUB_LIST_SUCCESS"
export const SET_LOADING_HUB_LIST = "SET_LOADING_HUB_LIST"

export const FETCH_ACCESS_HUB_COUNT = "FETCH_ACCESS_HUB_COUNT"
export const FETCH_HUB_COUNT = "FETCH_HUB_COUNT"

export const GET_HUB_DETAILS_SUCCESS = "GET_HUB_DETAILS_SUCCESS"
export const SET_LOADING_HUB_DETAIL = "SET_LOADING_HUB_DETAIL"
export const SET_SAVE_HUB = "SET_SAVE_HUB"
export const GET_BANNER = "GET_BANNER"
export const SET_UPDATE_BANNER = "SET_UPDATE_BANNER"
export const SET_DELETE_HUB = "SET_DELETE_HUB"
export const SET_REMOVE_INVITATION_HUB = "SET_REMOVE_INVITATION_HUB"

export const GET_SWITCH_DATA = "GET_SWITCH_DATA"

export const SET_TOP_MOST_MODAL = "SET_TOP_MOST_MODAL"
export const SET_PERMISSIONS = "SET_PERMISSIONS"

export const FETCH_SHARED_DASHBOARD_ACCESS = "FETCH_SHARED_DASHBOARD_ACCESS"

export const SET_USER_IMAGE_BANNER = "SET_USER_IMAGE_BANNER"
export const DELETE_BANNER_IMAGE = "DELETE_USER_IMAGE_BANNER"
export const SET_REMOVING_USER_BANNER = "SET_REMOVING_USER_BANNER"
