export const SET_LOADING = "SET_LOADING"
export const LIST_LOG_CALENDAR = "LIST_LOG_CALENDAR"
export const LIST_PUBLIC_LOG_CALENDAR = "LIST_PUBLIC_LOG_CALENDAR"
export const GET_LIST_CALENDAR_ITEMS = "GET_LIST_CALENDAR_ITEMS"
export const GET_LIST_ANNOUNCEMENTS_ITEMS = "GET_LIST_ANNOUNCEMENTS_ITEMS"
export const GET_PUBLIC_LIST_CALENDAR_ITEMS = "GET_PUBLIC_LIST_CALENDAR_ITEMS"
export const DELETE_CALENDAR_EVENT = "DELETE_CALENDAR_EVENT"
export const SET_CALENDAR_COUNT = "SET_CALENDAR_COUNT"
export const SET_CALENDAR_LOADING = "SET_CALENDAR_LOADING"

export const RSVP_LIST = "RSVP_LIST"
export const RSVP_RESPONSE_LIST = "RSVP_RESPONSE_LIST"
export const SET_RSVP_DATA = "SET_RSVP_DATA"
